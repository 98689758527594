import * as mapService from "../../../store/mapService";

export const verifyLocation = {
  getMessage: (field, params, data) =>
    (data && data.message) || "Something went wrong",
  validate: (value, [token]) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async resolve => {
      if (token === "test") {
        return resolve({
          valid: true
        });
      }

      const cityState =
        value.city.length > 0 ||
        value.state.length > 0 ||
        value.country.length > 0;
      const latLong = value.lat.length > 0 || value.long.length > 0;

      if (!cityState && !latLong) {
        return resolve({
          valid: false,
          data: {
            message:
              "Location (set by map or enter city/state/country) is required."
          }
        });
      }

      if (cityState) {
        const latLong = await mapService.geocode(
          token,
          value.city,
          value.state,
          value.country
        );
        if (latLong.lat && latLong.long) {
          return resolve({
            valid: true
          });
        }

        return resolve({
          valid: false,
          data: {
            message:
              "The location cannot be converted to a lat/long please update or use lat/long or map selection."
          }
        });
      }

      if (latLong) {
        const location = await mapService.reverseGeocode(
          token,
          value.lat,
          value.long
        );
        if (location.location !== "Unknown location") {
          return resolve({
            valid: true
          });
        }

        return resolve({
          valid: false,
          data: {
            message:
              "The lat/long cannot be converted to a friendly location please update or use city/state or map selection."
          }
        });
      }

      resolve({
        valid: true
      });
    })
};
