
import Vue from "vue";
import { Validator } from "vee-validate";
import { validDate, dateLessThan, dateGreaterThan } from "./Rules/dateRule";
// eslint-disable-next-line no-unused-vars
import { MarkerType } from "@/store/mapService";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MarkerInfo",
  inject: ["parentValidator"],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      marker: Object.assign({}, this.value) as Object,
      radios: "" as string,
      markerType: MarkerType.date as MarkerType
    };
  },
  computed: {
    ...mapGetters(["currMap", "mapTitles"]),
    maps(): string {
      return this.mapTitles.join();
    }
  },
  watch: {
    value: {
      handler() {
        if (this.marker !== this.value) {
          this.marker = Object.assign({}, this.value);
        }
      },
      deep: true
    },
    marker: {
      handler() {
        this.$emit("input", this.marker);
      },
      deep: true
    }
  },
  mounted() {
    this.markerType = this.currMap.markerType;
  },
  methods: {
    getMarkerTypeDate() {
      return MarkerType.date;
    },
    getMarkerTypeNumber() {
      return MarkerType.number;
    }
  },
  created() {
    // @ts-ignore
    this.$validator = this.parentValidator;
    Validator.extend("validDate", validDate);
    // @ts-ignore
    Validator.extend("dateLessThan", dateLessThan, {
      hasTarget: true
    });

    // @ts-ignore
    Validator.extend("dateGreaterThan", dateGreaterThan, {
      hasTarget: true
    });
  }
});
