<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12>
        <v-text-field
          v-model.trim="extra.link.url"
          v-validate="{ url: { require_protocol: true } }"
          :error-messages="errors.collect('website url')"
          label="Website (uri)"
          data-vv-name="website url"
          data-vv-scope="extra"
          required
        />
      </v-flex>
      <v-flex xs12>
        <v-text-field
          v-model.trim="extra.link.title"
          v-validate="`${extra.link.url.length > 0 ? 'required' : ''}`"
          :error-messages="errors.collect('website title')"
          label="Website (name)"
          data-vv-name="website title"
          data-vv-scope="extra"
          required
        />
      </v-flex>

      <v-flex xs12>
        <v-text-field
          v-model.trim="extra.addInfo"
          label="Additional info"
          data-vv-name="addInfo"
          required
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "MarkerExtra",
  inject: ["parentValidator"],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      extra: Object.assign({}, this.value)
    };
  },
  watch: {
    value: {
      handler() {
        if (this.extra !== this.value) {
          this.extra = Object.assign({}, this.value);
        }
      },
      deep: true
    },
    extra: {
      handler() {
        this.$emit("input", this.extra);
      },
      deep: true
    }
  },
  created() {
    this.$validator = this.parentValidator;
  }
};
</script>
