const checkdate = value => {
  const count = (value.match(/\//g) || []).length;

  if (count === 0) {
    const year = parseInt(value);
    if (isNaN(year) || year < 0 || year > 2100) {
      return null;
    }
    let date = new Date();
    date.setFullYear(parseInt(year));
    return date;
  } else if (count === 1) {
    let dateParts = value.split("/");
    const month = dateParts[0];
    const year = dateParts[1];
    if (isNaN(year) || year < 0 || year > 2100) {
      return null;
    }

    if (isNaN(month) || month < 1 || month > 12) {
      return null;
    }
    return new Date(year, month - 1);
  } else if (count === 2) {
    let dateParts = value.split("/");
    const month = dateParts[0];
    const day = dateParts[1];
    const year = dateParts[2];
    if (isNaN(year) || year < 0 || year > 2100) {
      return null;
    }

    if (isNaN(month) || month < 1 || month > 12) {
      return null;
    }

    if (isNaN(day) || day < 1 || day > 31) {
      return null;
    }

    return new Date(year, month - 1, day);
  }

  return null;
};

export const validDate = {
  getMessage: field =>
    `${field} must be a valid date (valid formats are yyyy, m{m}/d{d}/yyyy, m{m}/yyyy).`,
  validate: value => {
    const date = checkdate(value);

    if (date === null) {
      return false;
    }

    return true;
  }
};

export const dateLessThan = {
  getMessage: field => `${field} must less than end date`,
  validate: (value, [otherValue]) => {
    const date = checkdate(value);

    if (date === null) {
      return true;
    }

    const endDate = checkdate(otherValue);

    if (endDate !== null) {
      return date <= endDate;
    }

    return true;
  }
};

export const dateGreaterThan = {
  getMessage: field => `${field} must be  greater than start date`,
  validate: (value, [otherValue]) => {
    const date = checkdate(value);

    if (date === null) {
      return true;
    }

    const startDate = checkdate(otherValue);

    if (startDate !== null) {
      return date >= startDate;
    }

    return true;
  }
};
