<template>
  <div style="height:250px" class="map-container"></div>
</template>

<script>
export default {
  props: {
    latitude: {
      type: Number,
      default: 55.01657628017477
    },
    longitude: {
      type: Number,
      default: -7.309233337402361
    }
  },
  data() {
    return {
      lat: this.latitude,
      lng: this.longitude,
      manualSetAllowed: true,
      allowManualSetTimer: null,
      map: null
    };
  },
  watch: {
    latitude() {
      if (!isNaN(this.latitude) && this.manualSetAllowed) {
        this.resetMarkerPosition();
      }
    },
    longitude() {
      if (!isNaN(this.latitude) && this.manualSetAllowed) {
        this.resetMarkerPosition();
      }
    }
  },
  mounted() {
    /* global google */

    // Set coordinates

    let myLatlng = new google.maps.LatLng(
      this.lat ? this.lat : 55.01657628017477,
      this.lng ? this.lng : -7.309233337402361
    );

    // Options
    let mapOptions = {
      center: myLatlng,
      zoom: 5
    };
    // Apply options
    this.map = new google.maps.Map(this.$el, mapOptions);
    // Add marker
    let marker = new google.maps.Marker({
      position: myLatlng,
      map: this.map
    });
    marker.setMap(this.map);
    google.maps.event.addListener(this.map, "center_changed", () => {
      this.manualSetAllowed = false;
      let lat = this.map.getCenter().lat();
      let lon = this.map.getCenter().lng();
      let newLatLng = { lat: lat, lng: lon };
      marker.setPosition(newLatLng);
      this.$emit("locationUpdated", newLatLng);

      clearTimeout(this.allowManualSetTimer);
      setTimeout(() => (this.manualSetAllowed = true), 250);
    });
  },
  methods: {
    resetMarkerPosition() {
      this.lat = this.latitude;
      this.lng = this.longitude;
      let center = new google.maps.LatLng(this.lat, this.lng);
      this.map.panTo(center);
    }
  }
};
</script>
