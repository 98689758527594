<template>
  <div>
    <v-tabs centered grow>
      <v-tab key="latlong" ripple> <span class="logo_text">By Map</span></v-tab>
      <v-tab key="city" ripple>
        <span class="logo_text">By City/State</span></v-tab
      >
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <map-location-selector
                    :latitude="parseFloat(location.lat)"
                    :longitude="parseFloat(location.long)"
                    @locationUpdated="locationUpdated"
                  />
                </v-flex>
                <v-flex>
                  <v-expansion-panels :flat="true">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <template v-slot:default="{ open }">
                          <v-row no-gutters>
                            <v-col cols="4">Enter manually</v-col>
                            <v-col cols="8" class="text--secondary">
                              <v-fade-transition leave-absolute>
                                <span v-if="open" key="0">
                                  Please enter a latitude and longitude for your
                                  location
                                </span>
                              </v-fade-transition>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-text-field
                          :value="location.lat"
                          v-debounce:500="l => (location.lat = l)"
                          label="latitude"
                          data-vv-name="Latitude"
                        />
                        <v-text-field
                          :value="location.long"
                          v-debounce:500="l => (location.long = l)"
                          label="longitude"
                          data-vv-name="Longitude"
                        />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-select
                    v-if="currMap.cityStates && currMap.cityStates.length > 0"
                    v-model="cityState"
                    :items="currMap.cityStates"
                    item-text="display"
                    label="Select location"
                    return-object
                    hint="or enter manually below"
                    persistent-hint
                  />
                </v-flex>

                <v-flex xs12 sm6>
                  <v-text-field
                    v-model.trim="location.city"
                    :error="errors.has('location') && location.city.length > 0"
                    label="City/Place"
                    data-vv-name="city"
                    required
                  />
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model.trim="location.state"
                    :error="errors.has('location') && location.state.length > 0"
                    label="State/Province/Region"
                    data-vv-name="state"
                    required
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model.trim="location.country"
                    :error="
                      errors.has('location') && location.country.length > 0
                    "
                    label="Country"
                    data-vv-name="country"
                    required
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mapLocationSelector from "./LocationSelector";

export default {
  // switch to TS
  name: "MarkerLocation",
  components: {
    mapLocationSelector
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      location: Object.assign({}, this.value),
      cityState: null,
      latLong: null
    };
  },
  computed: {
    ...mapGetters(["currMap"]),
    city() {
      return this.location.city;
    },
    state() {
      return this.location.state;
    },
    country() {
      return this.location.country;
    }
  },
  watch: {
    value: {
      handler() {
        if (this.location !== this.value) {
          this.location = Object.assign({}, this.value);
        }
      },
      deep: true
    },
    cityState() {
      this.location.city = this.cityState.city;
      this.location.state = this.cityState.state;
      this.location.country = this.cityState.country;
      this.location.lat = "";
      this.location.long = "";
      this.$emit("input", this.location);
    },
    latLong(newVal, oldVal) {
      if (oldVal !== null) {
        this.location.lat = this.latLong.lat.toString();
        this.location.long = Object.prototype.hasOwnProperty.call(
          this.latLong,
          "long"
        )
          ? this.latLong.long.toString()
          : this.latLong.lng.toString();
        this.location.city = "";
        this.location.state = "";
        this.location.country = "";
        this.$emit("input", this.location);
      }
    },
    city(newVal, oldVal) {
      if (oldVal !== "") {
        this.location.lat = "";
        this.location.long = "";
        this.$emit("input", this.location);
      }
    },
    state(newVal, oldVal) {
      if (oldVal !== "") {
        this.location.lat = "";
        this.location.long = "";
        this.$emit("input", this.location);
      }
    },
    country(newVal, oldVal) {
      if (oldVal !== "") {
        this.location.lat = "";
        this.location.long = "";
        this.$emit("input", this.location);
      }
    }
  },
  methods: {
    locationUpdated(latlng) {
      this.latLong = latlng;
    }
  }
};
</script>
